import useSWR from "swr";
import { fetcher } from "utils/api";
import { Heading, Stack, Divider } from "@chakra-ui/react";
import AccountsList from "./AccountsList";
import Suspense from "components/Suspense";
import ErrorBoundary from "components/ErrorBoundary";

type PlatformPageProps = {
  name: string;
  icon: JSX.Element;
  platform: string;
  signIn?: JSX.Element;
  postEditor?: JSX.Element;
};

export default function PlatformPage({
  platform,
  name,
  icon,
  signIn,
  postEditor,
}: PlatformPageProps) {
  const {
    data: { accounts },
    mutate,
  } = useSWR(`/accounts?platform=${platform}`, fetcher, {
    suspense: true,
  });

  return (
    <Stack gap={4}>
      <Heading display="inline-flex" alignItems="center" gap={4}>
        {icon} {name}
      </Heading>
      <Stack w="100%" gap={4}>
        <Stack>
          <Suspense>
            <AccountsList accounts={accounts} update={mutate} />
          </Suspense>
          {signIn && (
            <Suspense>
              <ErrorBoundary>{signIn}</ErrorBoundary>
            </Suspense>
          )}
        </Stack>
        <Divider />
        {postEditor && (
          <Stack w={{ sm: "100%", "2xl": "50%" }}>
            <Heading size="md">Create post</Heading>
            {postEditor}
          </Stack>
        )}
      </Stack>
    </Stack>
  );
}
