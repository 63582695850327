import { useRef, useEffect, useState, useCallback } from "react";
import {
  Box,
  Button,
  Flex,
  Stack,
  useToast,
  Input,
  Text,
  Divider,
  IconButton,
  Accordion,
  AccordionPanel,
  AccordionButton,
  AccordionItem,
  AccordionIcon,
  useRadioGroup,
  FormControl,
  FormLabel,
  useRadio,
} from "@chakra-ui/react";
import FormInput from "components/stepper/FormInput";
import { useForm, FormProvider, useFormContext } from "react-hook-form";
import { createDraft, sendEmail } from "../api";
import { BiSend } from "react-icons/bi";
import { MdSaveAs } from "react-icons/md";
import { LuClipboardCopy } from "react-icons/lu";
import { MdOutlineAttachFile } from "react-icons/md";
import {
  FaImage,
  FaFile,
  FaFileWord,
  FaFileExcel,
  FaFileCsv,
  FaFilePdf,
  FaFilePowerpoint,
} from "react-icons/fa6";
import { LuFileJson } from "react-icons/lu";
import { BiSolidFileTxt } from "react-icons/bi";
import { CiCircleRemove } from "react-icons/ci";
import { Attachment, SendEmail } from "types";
import { BiLayout } from "react-icons/bi";
import { FiType } from "react-icons/fi";

import Editor from "components/html-editor/Editor";
import EmailEditor, { EditorRef } from "react-email-editor";
import RephraseContent from "../../content-generator/components/RephraseContent";
import { colors } from "theme/styles";

type EditorType = "html" | "block";

type MailEditorProps = {
  message?: SendEmail | any;
  defaultEditorType?: EditorType;
  selectEditor?: boolean;
  onSend?: () => void;
  disabled?: boolean;
  onChange?: ({ subject, body }: any) => void;
};
export default function MailEditor({
  message,
  defaultEditorType = "html",
  selectEditor = false,
  onSend,
  disabled = false,
  onChange,
}: MailEditorProps) {
  const [editorType, setEditorType] = useState<EditorType>(defaultEditorType);
  const toast = useToast();
  const form = useForm();
  const { handleSubmit } = form;

  useEffect(() => {
    if (
      message &&
      (message.body !== form.getValues("body") ||
        message.subject !== form.getValues("subject"))
    ) {
      form.reset(message);
    }
  }, [form, message, message.body]);

  const subject = form.watch("subject", message.subject);
  const body = form.watch("body", message.body);

  useEffect(() => {
    onChange?.({ subject, body });
  }, [subject, body, onChange]);

  const emailEditorRef = useRef<EditorRef>(null);

  const getContent = useCallback(
    (callback: any) => {
      if (editorType === "html") {
        callback(body);
      } else if (editorType === "block") {
        const unlayer = emailEditorRef.current?.editor;

        unlayer?.exportHtml((data) => {
          const { html } = data;
          callback(html);
        });
      }
    },
    [editorType, body, emailEditorRef]
  );

  const onSubmit = (fields: any) => {
    getContent((html: string) => {
      const promise = sendEmail({
        ...fields,
        body: html,
      });

      promise.then(() => {
        onSend?.();
      });

      toast.promise(promise, {
        loading: { title: "Sending email..." },
        success: { title: "Email sent!" },
        error: { title: "Error sending email" },
      });
    });
  };

  return (
    <FormProvider {...form}>
      <Stack>
        <Headers />

        {selectEditor && (
          <SelectEditor
            defaultEditorType={defaultEditorType}
            setEditorType={setEditorType}
          />
        )}

        {editorType === "html" && (
          <Editor
            key={body}
            content={body}
            onUpdate={(html) => form.setValue("body", html)}
            disabled={disabled}
          />
        )}
        {editorType === "block" && <EmailEditor ref={emailEditorRef} />}

        <Attachments />

        <Divider />

        <Flex gap={4} flexWrap="wrap">
          <Button
            onClick={handleSubmit(onSubmit)}
            style={{ width: "fit-content" }}
            bgColor={colors.blue}
            color="white"
            leftIcon={<BiSend />}
          >
            Send
          </Button>

          <SaveAsDraft getContent={getContent} />

          <AttachFiles />

          <ExportHTML getContent={getContent} />

          <RephraseContent
            content={body}
            onUpdate={(body) => form.setValue("body", body)}
            title="Rephrase"
            tooltip="Update the intent of the email or reword the content"
          />
        </Flex>
      </Stack>
    </FormProvider>
  );
}

function RadioCard(props: any) {
  const { getInputProps, getRadioProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getRadioProps();

  return (
    <Box as="label">
      <input {...input} />
      <Box
        {...checkbox}
        cursor="pointer"
        borderWidth="1px"
        borderRadius="md"
        boxShadow="md"
        _checked={{
          bg: "gray.600",
          color: "white",
        }}
        _focus={{
          boxShadow: "outline",
        }}
        px={2}
        py={2}
      >
        {props.children}
      </Box>
    </Box>
  );
}

function SelectEditor({ defaultEditorType, setEditorType }: any) {
  const options = [
    { value: "html", icon: <FiType /> },
    { value: "block", icon: <BiLayout /> },
  ];

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: "editorType",
    defaultValue: defaultEditorType,
    onChange: (value) => setEditorType(value),
  });

  const group = getRootProps();

  return (
    <FormControl>
      <Flex align="center">
        <FormLabel>Editor:</FormLabel>
        <Flex {...group} gap={2}>
          {options.map(({ value, icon }) => {
            const radio = getRadioProps({ value: value.toLowerCase() });
            return (
              <RadioCard key={value} {...radio}>
                <Flex alignItems="center" gap={2}>
                  {icon}
                </Flex>
              </RadioCard>
            );
          })}
        </Flex>
      </Flex>
    </FormControl>
  );
}

function Headers() {
  return (
    <Stack>
      <FormInput
        field="from"
        label="From"
        placeholder="Email address of sender"
      />
      <Accordion allowToggle border="none">
        <AccordionItem border="none">
          <Flex>
            <FormInput
              field="to"
              label="To"
              placeholder="Email address of recipient"
            />
            <AccordionButton w="fit-content">
              cc/bcc
              <AccordionIcon />
            </AccordionButton>
          </Flex>
          <AccordionPanel>
            <FormInput field="cc" label="cc" />
            <FormInput field="bcc" label="bcc" />
          </AccordionPanel>
        </AccordionItem>
      </Accordion>

      <FormInput
        field="subject"
        label="Subject"
        placeholder="Topic of the email"
      />
    </Stack>
  );
}

function SaveAsDraft({ getContent }: any) {
  const toast = useToast();
  const form = useFormContext();
  const onSave = (fields: any) => {
    getContent((html: string) => {
      const promise = createDraft({ ...fields, body: html });

      toast.promise(promise, {
        loading: { title: "Creating draft..." },
        success: { title: "Draft created!" },
        error: { title: "Error creating draft" },
      });
    });
  };

  return (
    <Button
      onClick={form.handleSubmit(onSave)}
      style={{ width: "fit-content" }}
      leftIcon={<MdSaveAs />}
    >
      Save as draft
    </Button>
  );
}

function AttachFiles() {
  const form = useFormContext();
  // const toast = useToast();

  const inputRef = useRef(null);
  const attachments = form.watch("attachments", []);

  const handleFileChange = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const attachment = {
          fileName: file.name,
          data: (e.target.result as string).split(",")[1], // b64 string
        };
        form.setValue("attachments", [...attachments, attachment]);
      };
      reader.readAsDataURL(file); // read file as text, you can change to other methods based on file type
    }
  };

  return (
    <>
      <Input
        ref={inputRef}
        type="file"
        multiple={true}
        onChange={handleFileChange}
        display="none"
      />
      <Button
        style={{ width: "fit-content" }}
        leftIcon={<MdOutlineAttachFile />}
        onClick={() => inputRef.current?.click()}
      >
        Attach
      </Button>
    </>
  );
}

function Attachments() {
  const form = useFormContext();
  const attachments = form.watch("attachments", []);

  const icons: { [key: string]: JSX.Element } = {
    png: <FaImage />,
    jpg: <FaImage />,
    jpeg: <FaImage />,
    doc: <FaFileWord />,
    docx: <FaFileWord />,
    xls: <FaFileExcel />,
    xlsx: <FaFileExcel />,
    csv: <FaFileCsv />,
    pdf: <FaFilePdf />,
    json: <LuFileJson />,
    txt: <BiSolidFileTxt />,
    ppt: <FaFilePowerpoint />,
    pptx: <FaFilePowerpoint />,
  };

  const removeAttachment = (fileName: string) => {
    form.setValue(
      "attachments",
      attachments.filter(
        (attachment: Attachment) => attachment.fileName !== fileName
      )
    );
  };

  return (
    <Flex gap={4}>
      {attachments.map(({ fileName }: Attachment) => (
        <Flex key={fileName} gap={2} alignItems="center">
          {icons[fileName.split(".").pop().toLocaleLowerCase()] ?? <FaFile />}

          <Text>{fileName}</Text>

          <IconButton
            aria-label="Remove attachment"
            icon={<CiCircleRemove fontSize={25} />}
            size="lg"
            background="none"
            onClick={() => removeAttachment(fileName)}
          />
        </Flex>
      ))}
    </Flex>
  );
}

function ExportHTML({ getContent }: any) {
  const toast = useToast();

  const exportHtml = () => {
    getContent((html: string) => {
      navigator.clipboard.writeText(html);
      toast({
        title: "HTML copied to clipboard",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    });
  };

  return (
    <Button
      onClick={exportHtml}
      style={{ width: "fit-content" }}
      leftIcon={<LuClipboardCopy />}
    >
      Export HTML
    </Button>
  );
}
