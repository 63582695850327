import { useToast } from "@chakra-ui/react";
import { FaInstagram } from "react-icons/fa";
import PlatformPage from "./PlatformPage";
import { addInstagramAccount, createInstagramPost } from "../api";
import { useSWRConfig } from "swr";
import { LoginSocialInstagram, IResolveParams } from "reactjs-social-login";

// CUSTOMIZE ANY UI BUTTON
import { InstagramLoginButton } from "react-social-login-buttons";
import { CreateInstagramPost } from "types";
import SocialMediaPost from "views/admin/connections/components/SocialMediaPost";

export default function Instagram() {
  return (
    <PlatformPage
      platform="instagram"
      name="Instagram"
      icon={<FaInstagram />}
      signIn={<SignIn />}
      postEditor={<InstagramPost />}
    />
  );
}

function SignIn() {
  const { mutate } = useSWRConfig();

  const toast = useToast();

  const responseInstagram = (code: string) => {
    const promise = addInstagramAccount(code);

    promise
      .then(() => {
        mutate("/accounts?platform=instagram");
        mutate("instagram-accounts");
      })
      .catch(() => {});

    toast.promise(promise, {
      loading: { title: "Connecting account..." },
      success: { title: "Account connected" },
      error: {
        title: "Something went wrong when connecting the account.",
      },
    });
  };

  const scopes = ["business_basic", "business_content_publish"];

  return (
    <LoginSocialInstagram
      isOnlyGetCode
      client_id={process.env.REACT_APP_INSTAGRAM_APP_ID}
      client_secret=""
      redirect_uri={window.location.href}
      onResolve={({ data: { code } }: IResolveParams) => {
        responseInstagram(code);
      }}
      onReject={(err: any) => {
        // console.log(err);
        toast({
          title: "Something went wrong when connecting the account.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }}
      scope={scopes.join(",")}
    >
      <InstagramLoginButton style={{ width: "fit-content" }} />
    </LoginSocialInstagram>
  );
}

type InstagramPostProps = {
  onGenerate?: () => Promise<string>;
  link?: string;
};
export function InstagramPost({ onGenerate, link }: InstagramPostProps) {
  const toast = useToast();
  const onSubmit = ({ text, imageUrl, username }: CreateInstagramPost) => {
    const promise = createInstagramPost({ username, text, imageUrl });

    toast.promise(promise, {
      loading: { title: "Publishing post..." },
      success: { title: "Post published" },
      error: { title: "Something went wrong when publishing the post." },
    });

    return promise;
  };

  return (
    <SocialMediaPost
      onSubmit={onSubmit}
      platform="instagram"
      onGenerate={onGenerate}
      showImage={true}
      textLabel="Caption"
    />
  );
}
