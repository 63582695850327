import { Navigate } from "react-router-dom";
import AdminLayout from "./layouts/admin";
import SignIn from "views/auth/SignIn";
import Analytics from "views/admin/analytics";
import { LinkedInCallback } from "react-linkedin-login-oauth2";
import LandingPage from "views/pages/landing-page";
import PrivacyPolicy from "views/pages/privacy-policy";
import ComposeMail from "views/admin/mailer/components/ComposeMail";
import ThreadMessages from "views/admin/mailer/components/ThreadMessages";
import { lazy } from "react";
import Organisations from "views/admin/organisations";
import OrganisationPage from "views/admin/organisations/components/OrganisationPage";
import News from "views/admin/news";
import Instagram from "views/admin/connections/components/Instagram";
import WebScraper from "views/admin/web-scraper";

export const WebsitePage = lazy(
  () => import("views/admin/websites/components/WebsitePage")
);
export const Facebook = lazy(
  () => import("views/admin/connections/components/Facebook")
);
export const LinkedIn = lazy(
  () => import("views/admin/connections/components/LinkedIn")
);
export const Twitter = lazy(
  () => import("views/admin/connections/components/Twitter")
);
export const Connections = lazy(() => import("views/admin/connections"));
export const SEO = lazy(() => import("views/admin/seo"));
export const Settings = lazy(() => import("views/admin/settings"));
export const Websites = lazy(() => import("views/admin/websites"));
export const Profile = lazy(() => import("views/admin/profile"));
export const ContentGenerator = lazy(
  () => import("views/admin/content-generator")
);
export const SignUp = lazy(() => import("views/auth/SignUp"));
export const Mailer = lazy(() => import("views/admin/mailer"));
export const SalesAssistant = lazy(() => import("views/admin/sales-assistant"));

export const routes = [
  {
    name: "Home",
    path: "/",
    children: [
      {
        path: "",
        element: <LandingPage />,
      },
      {
        name: "Sign In",
        path: "sign-in",
        element: <SignIn />,
      },
      {
        name: "Sign Up",
        path: "sign-up",
        element: <SignUp />,
      },
      {
        name: "Privacy Policy",
        path: "privacy-policy",
        element: <PrivacyPolicy />,
      },
      {
        name: "Admin",
        path: "admin",
        element: <AdminLayout />,
        children: [
          {
            path: "",
            element: <Navigate to="organisations" />,
          },
          {
            name: "Organisations",
            path: "organisations",
            element: <Organisations />,
          },
          {
            name: "Organisations",
            path: "organisations/:organisationId",
            element: <OrganisationPage />,
          },
          {
            name: "Websites",
            path: "websites",
            element: <Websites />,
          },
          {
            path: "websites/:websiteId",
            element: <WebsitePage />,
          },

          {
            name: "Blogger",
            path: "blogger",
            element: <ContentGenerator />,
          },
          {
            name: "SEO",
            path: "seo",
            element: <SEO />,
          },
          {
            name: "Analytics",
            path: "analytics",

            element: <Analytics />,
          },
          {
            name: "Mail",
            path: "mail",

            children: [
              {
                path: "",
                element: <Mailer />,
              },
              {
                name: "Compose Email",
                path: "compose",
                element: <ComposeMail />,
              },
              {
                name: "Thread",
                path: "threads/:threadId",
                element: <ThreadMessages />,
              },
            ],
          },
          {
            name: "Profile",
            path: "profile",

            element: <Profile />,
          },
          {
            name: "Settings",
            path: "settings",
            element: <Settings />,
          },
          {
            name: "Social Media",
            path: "social-media",
            children: [
              {
                path: "",
                element: <Connections />,
              },
              {
                name: "X",
                path: "x",
                element: <Twitter />,
              },
              {
                name: "LinkedIn",
                path: "linkedin",
                element: <LinkedIn />,
              },
              {
                name: "Facebook",
                path: "facebook",
                element: <Facebook />,
              },
            ],
          },
          {
            name: "Connections",
            path: "connections",
            children: [
              {
                path: "",
                element: <Connections />,
              },
              {
                name: "X",
                path: "x",
                element: <Twitter />,
              },
              {
                name: "LinkedIn",
                path: "linkedin",
                element: <LinkedIn />,
              },
              {
                name: "Facebook",
                path: "facebook",
                element: <Facebook />,
              },
              {
                name: "Instagram",
                path: "instagram",
                element: <Instagram />,
              },
            ],
          },
          {
            name: "News",
            path: "news",
            element: <News />,
          },
          {
            name: "Web Scraper",
            path: "web-scraper",
            element: <WebScraper />,
          },
          {
            name: "Web Scraper",
            path: "web-scraper",
            element: <WebScraper />,
          },
          {
            name: "Sales Assistant",
            path: "sales-assistant",
            element: <SalesAssistant />,
          },
        ],
      },
      {
        name: "Log in success",
        path: "linkedin",
        element: <LinkedInCallback />,
      },
    ],
  },
];
