import {
  Alert,
  Flex,
  Link,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import { getReadableDate } from "utils/date";
import { Organisation } from "types";
import useSWR from "swr";
import { fetcher } from "utils/api";

export default function OrganisationsList() {
  const {
    data: { organisations },
  } = useSWR("organisations", () => fetcher("/organisations"), {
    suspense: true,
  });

  if (organisations.length === 0) {
    return <Alert status="info">No organisations found</Alert>;
  }
  return (
    <Flex>
      <TableContainer>
        <Table variant="striped">
          <Thead>
            <Tr>
              <Th>Name</Th>
              <Th>Website URL</Th>
              <Th isNumeric>Added</Th>
            </Tr>
          </Thead>
          <Tbody>
            {organisations.map((organisation: Organisation) => (
              <Tr key={organisation.id}>
                <Td>
                  <Link href={`organisations/${organisation.id}`}>
                    {organisation.name}
                  </Link>
                </Td>
                <Td>
                  <Link
                    color="teal.500"
                    href={organisation.websiteUrl}
                    isExternal
                  >
                    {organisation.websiteUrl} <ExternalLinkIcon mx="2px" />
                  </Link>
                </Td>
                <Td>{getReadableDate(organisation.createdAt)}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Flex>
  );
}
