import { createContext, useContext, useState } from "react";
import {
  Box,
  Card,
  Divider,
  Flex,
  Stack,
  Tag,
  Text,
  useToast,
  Menu,
  MenuItem,
  MenuButton,
  MenuList,
  Button,
} from "@chakra-ui/react";
import { Message } from "types";
import { getReadableDate } from "utils/date";
import ReplyMail from "./ReplyMail";
import MailEditor from "./MailEditor";
import { Letter } from "react-letter";
import Attachments from "./Attachments";
import { deleteMessage } from "../api";
import { HiDotsVertical } from "react-icons/hi";
import { useSWRConfig } from "swr";
import Modal from "components/Modal";

const EmailContext = createContext({
  state: { message: null, reply: false },
  updateState: (newState: any) => {},
});

type EmailProps = {
  message: Message;
  autoGenerateReply?: boolean;
};
export default function Email({
  message,
  autoGenerateReply = true,
}: EmailProps) {
  const [state, setState] = useState({ message, reply: false });

  return (
    <EmailContext.Provider value={{ state, updateState: setState }}>
      <Card padding={4}>
        <Flex justify="flex-end">
          <EmailMenu />
        </Flex>

        {message.isDraft ? (
          <MailEditor message={message} />
        ) : (
          <Stack gap={4}>
            <Stack>
              <Text>
                From: <Tag>{message.from}</Tag>
              </Text>
              <Text>
                To: <Tag>{message.to}</Tag>
              </Text>
              {getReadableDate(message.date)}
            </Stack>
            <Divider marginY={2} />

            {message.subject && (
              <>
                <Text>{message.subject}</Text>
                <Divider marginY={2} />
              </>
            )}

            <Box>
              <Letter html={message.body} />
            </Box>

            <Attachments attachments={message.attachments} />

            <ReplyMail
              originalMessage={message}
              autoGenerateReply={autoGenerateReply}
            />
          </Stack>
        )}
      </Card>
    </EmailContext.Provider>
  );
}

function EmailMenu() {
  const { mutate } = useSWRConfig();
  const toast = useToast();

  const {
    state: { message, reply },
    updateState,
  } = useContext(EmailContext);

  const onUpdate = () => {
    mutate(`/threads/${message.threadId}/messages`);
  };

  // const onDeleteDraft = () => {
  //   console.log(message);
  //   const promise = deleteDraft(message.id);

  //   promise.then(() => onUpdate());

  //   toast.promise(promise, {
  //     loading: { title: "Deleting draft..." },
  //     success: { title: "Draft deleted!" },
  //     error: { title: "Error deleting draft" },
  //   });
  // };

  const onDeleteMessage = () => {
    const promise = deleteMessage(message.id);

    promise.then(() => onUpdate());

    toast.promise(promise, {
      loading: { title: "Deleting message..." },
      success: { title: "Message deleted!" },
      error: { title: "Error deleting message" },
    });
  };

  const items: any = [
    {
      label: "Delete message",
      onClick: onDeleteMessage,
    },
    {
      label: "Reply",
      onClick: () => {
        updateState({ message, reply: true });
      },
    },
  ];

  if (message.isDraft) {
    return null;
  }

  return (
    <>
      <Menu>
        <MenuButton as={Button}>
          <HiDotsVertical />
        </MenuButton>
        <MenuList>
          {items.map(({ label, onClick }: any) => (
            <MenuItem key={label} onClick={onClick}>
              {label}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>

      <Modal
        isOpen={reply}
        body={<ReplyMail originalMessage={message} />}
        onClose={() => updateState({ message, reply: false })}
      />
    </>
  );
}
