import { useState } from "react";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Card,
  Button,
  Stack,
  useToast,
} from "@chakra-ui/react";
import parse from "html-react-parser";
import { useParams } from "react-router";
import useSWR from "swr";
import { Message } from "types";
import { fetcher } from "utils/api";
import Email from "./Email";
import { EmailHeader } from "./EmailHeader";
import { threadSummary } from "../api";
import { ErrorBoundary } from "@sentry/react";
import { MdFormatListBulleted } from "react-icons/md";

type ThreadMessagesProps = {
  threadId?: string;
  currentMessageId?: string;
};
export default function ThreadMessages({
  threadId,
  currentMessageId,
}: ThreadMessagesProps) {
  const params = useParams();
  threadId = threadId || params.threadId;
  const {
    data: { messages },
  } = useSWR(`/mails/threads/${threadId}/messages`, fetcher, {
    suspense: true,
  });

  currentMessageId =
    currentMessageId ??
    messages
      .reverse()
      .find((message: any) => !message?.labelIds?.includes("SENT"))?.id;

  return (
    <Stack w="100%">
      <ErrorBoundary>
        <ThreadSummary threadId={threadId} />
      </ErrorBoundary>
      <Accordion
        key={messages.length}
        defaultIndex={[
          messages.findIndex(
            (message: Message) => message.messageId === currentMessageId
          ),
        ]}
        allowMultiple
        w="100%"
      >
        {messages.map((message: Message, index: number) => (
          <AccordionItem key={`message-${index}`}>
            <AccordionButton width="100%" flex={1} alignItems="left">
              <EmailHeader message={message} />
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel>
              <Email message={message} />
            </AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>
    </Stack>
  );
}

type ThreadSummaryProps = {
  threadId?: string;
};
function ThreadSummary({ threadId }: ThreadSummaryProps) {
  const toast = useToast();
  const [summary, setSummary] = useState<string>();

  const generate = () => {
    const promise = threadSummary(threadId).then((res: any) => {
      setSummary(res.data.summary);
    });

    toast.promise(promise, {
      loading: { title: "Generating summary..." },
      success: { title: "Summary generated" },
      error: { title: "Error generating summary" },
    });
  };

  return (
    <Stack>
      <Button
        onClick={generate}
        leftIcon={<MdFormatListBulleted fontSize={20} />}
      >
        Generate thread summary
      </Button>
      {summary && <Card p={8}>{parse(summary)}</Card>}
    </Stack>
  );
}
