import {
  Alert,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Link,
  Select,
  Stack,
  Text,
  IconButton,
  useToast,
  Tooltip,
  Input,
  Image,
} from "@chakra-ui/react";
import { useMemo, useState, useEffect, useRef } from "react";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import { getPages, getSocialMediaAccounts } from "views/admin/connections/api";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import useSWR from "swr";
import { CiShare1 } from "react-icons/ci";
// import RephraseContent from "./RephraseContent";
import FormInput from "components/stepper/FormInput";
import { Account, Page } from "types";
import RephraseContent from "../../content-generator/components/RephraseContent";
import { MdFileUpload } from "react-icons/md";
import ImageGenerator, { ImageResponse } from "components/ImageGenerator";

const platformMaxText: { [key: string]: number } = {
  x: 280,
  linkedin: 3000,
  facebook: 63206,
  instagram: 2200,
};

type SocialMediaPostProps = {
  platform: string;
  onSubmit?: any;
  onGenerate?: () => Promise<string>;
  accountRequired?: boolean;
  showUrl?: boolean;
  showPages?: boolean;
  defaultValues?: any;

  getShareWindowLink?: ({
    text,
    username,
    url,
  }: {
    text: string;
    username: string;
    url?: string;
  }) => string;

  textLabel?: string;

  showImage?: boolean;
};

export default function SocialMediaPost({
  platform,
  onSubmit,
  onGenerate,
  accountRequired = true,
  getShareWindowLink,
  showUrl = false,
  showPages = false,
  defaultValues = {},
  textLabel = "Text",
  showImage = false,
}: SocialMediaPostProps) {
  const [sharedPost, setSharedPost] = useState(null);

  const accountsFetcher = () => getSocialMediaAccounts({ platform });

  const {
    data: { accounts },
  } = useSWR(`${platform}-accounts`, accountsFetcher, {
    suspense: true,
  });

  const textMaxLength = platformMaxText[platform];

  const schema = yup.object({
    text: yup
      .string()
      .max(textMaxLength ?? 100)
      .required("Text is required"),
    username: accountRequired
      ? yup.string().required("Username is required")
      : yup.string().nullable(),

    url: yup.string().nullable(),

    pageId: yup.string().nullable(),

    image: yup.mixed(),
  });

  const resolver = yupResolver(schema);
  const form = useForm({ resolver, defaultValues });

  const text = form.watch("text", "");
  const username = form.watch("username", "");
  const url = form.watch("url", "");

  const {
    data: { pages },
  } = useSWR(
    `${platform}-${username}-pages`,
    () =>
      showPages && username
        ? getPages({ platform, username })
        : { pages: null },
    {
      suspense: true,
    }
  );

  const createPost = ({ username, text, image }: any) => {
    const promise = onSubmit({
      username,
      text,
      imageUrl: image.url ?? image.data,
    });

    promise?.then((post: any) => {
      setSharedPost(post);
    });
  };

  const shareUrl = useMemo(() => {
    return (
      getShareWindowLink && url && getShareWindowLink({ text, username, url })
    );
  }, [getShareWindowLink, url, text, username]);

  useEffect(() => {
    if (accounts?.length > 0) {
      form.setValue("username", accounts[0].username);
    }
  }, [accounts, form]);

  useEffect(() => {
    if (pages?.length > 0) {
      form.setValue("pageId", pages[0].id);
    }
  }, [pages, form]);

  if (sharedPost) {
    return (
      <Flex gap={2}>
        <Published sharedPost={sharedPost} />
        <Button
          onClick={() => {
            setSharedPost(null);
            form.reset();
          }}
        >
          New post
        </Button>
      </Flex>
    );
  }

  return (
    <FormProvider {...form}>
      <Stack w="100%" gap={2}>
        {accountRequired && <AccountSelector accounts={accounts} />}

        {pages && <PageSelector pages={pages} />}

        {showImage && <UploadImage />}

        <TextInput textMaxLength={textMaxLength} label={textLabel} />

        {showUrl && <FormInput field="url" label="Share link" />}

        <Flex gap={4}>
          {onGenerate && (
            <Button
              onClick={() =>
                onGenerate().then((text: string) => form.setValue("text", text))
              }
            >
              Generate
            </Button>
          )}

          <Button onClick={form.handleSubmit(createPost)} colorScheme="blue">
            Post
          </Button>

          {shareUrl && <ShareWindow url={shareUrl} />}

          <RephraseContent
            content={text}
            onUpdate={(content: string) => form.setValue("text", content)}
          />
        </Flex>
      </Stack>
    </FormProvider>
  );
}

function TextInput({
  textMaxLength,
  label = "Text",
}: {
  textMaxLength: number;
  label: string;
}) {
  const form = useFormContext();
  const text = form.watch("text", "");

  return (
    <FormInput
      field="text"
      label={label}
      helperText={
        textMaxLength && (
          <Text
            size="sm"
            color={text.length > textMaxLength ? "red.500" : "gray.400"}
          >
            {text.length} / {textMaxLength}
          </Text>
        )
      }
      textarea={true}
    />
  );
}

function AccountSelector({ accounts }: { accounts: Account[] }) {
  const form = useFormContext();
  const username = form.watch("username");

  return (
    <FormControl>
      <FormLabel>Account</FormLabel>
      <Select
        placeholder="Select account"
        value={username}
        onChange={(e) => form.setValue("username", e.target.value)}
      >
        {accounts.map((account: any) => (
          <option key={account.id} value={account.username}>
            {account.username}
          </option>
        ))}
      </Select>
      {form.formState.errors.username && (
        <Alert status="error">
          {form.formState.errors.username.message as string}
        </Alert>
      )}
    </FormControl>
  );
}

function ShareWindow({ url }: { url: string }) {
  const toast = useToast();
  const form = useFormContext();
  const text = form.watch("text");

  return (
    <Tooltip label="Open window">
      <IconButton
        aria-label="Share"
        icon={<CiShare1 size={20} />}
        onClick={() => {
          navigator.clipboard.writeText(text);

          toast({
            title: "Text copied to clipboard. Paste it into your post.",
            status: "success",
            duration: 3000,
            isClosable: true,
            position: "bottom-left",
          });

          window.open(url, "_share", "width=600,height=600");
        }}
      />
    </Tooltip>
  );
}

function Published({ sharedPost }: any) {
  const { link } = sharedPost;

  return (
    <Flex alignItems="center" gap={4}>
      <Text>Your post has been published!</Text>
      {link && (
        <Link href={link} target="_blank" width="fit-content">
          <Button>View post</Button>
        </Link>
      )}
    </Flex>
  );
}

function PageSelector({ pages }: { pages: Page[] }) {
  const form = useFormContext();
  const pageId = form.watch("pageId");

  return (
    <FormControl>
      <FormLabel>Page</FormLabel>
      <Select
        placeholder="Select page"
        value={pageId}
        onChange={(e) => form.setValue("pageId", e.target.value)}
      >
        {pages.map((page: any) => (
          <option key={page.pageId} value={page.pageId}>
            {page.name}
          </option>
        ))}
      </Select>
      {form.formState.errors.pageId && (
        <Alert status="error">
          {form.formState.errors.pageId.message as string}
        </Alert>
      )}
    </FormControl>
  );
}

function UploadImage() {
  const form = useFormContext();
  const inputRef = useRef(null);
  const image = form.watch("image", {});
  const text = form.watch("text");

  const handleFileChange = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      // event handler for when the image file is loaded
      // sets the image field in the form to contain the file and data url of the image
      // file is the original file object
      // data is the data url of the image, which is the base64 encoded image data
      reader.onload = (e: any) => {
        form.setValue("image", {
          data: e.target.result,
        });
      };
      reader.readAsDataURL(file);
    }
  };

  const handleUrlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    form.setValue("image", { data: e.target.value });
  };

  const clearImage = () => {
    form.setValue("image", {});
  };

  return (
    <FormControl>
      <FormLabel>Image</FormLabel>
      <Stack spacing={4}>
        <Flex gap={2} align="center">
          {/* <Input
            ref={inputRef}
            type="file"
            accept="image/*"
            onChange={handleFileChange}
            display="none"
          />
          <Button
            leftIcon={<MdFileUpload />}
            onClick={() => inputRef.current?.click()}
          >
            Upload
          </Button> */}
          <Input
            placeholder="Enter image URL"
            onChange={handleUrlChange}
            value={image?.url}
          />
        </Flex>

        <Tooltip label={"Generate image based on caption"}>
          <ImageGenerator
            showInput={true}
            defaultDescription={text}
            onGenerate={(res: ImageResponse) =>
              form.setValue("image", { data: res?.url })
            }
          />
        </Tooltip>

        {(image?.url || image?.data) && (
          <Stack gap={4} w="fit-content">
            <Image
              src={image?.url ?? image?.data}
              alt="Preview"
              style={{ maxHeight: 250 }}
            />

            <Button onClick={clearImage} colorScheme="red" w="fit-content">
              Clear image
            </Button>
          </Stack>
        )}
      </Stack>
    </FormControl>
  );
}
