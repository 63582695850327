import { useEffect, useCallback } from "react";
import {
  Stack,
  Heading,
  Select,
  Flex,
  Tag,
  Text,
  Tooltip,
  IconButton,
  Alert,
} from "@chakra-ui/react";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import useSWR, { useSWRConfig } from "swr";
import { Organisation } from "types";
import { fetcher } from "utils/api";
import ArticlesList from "./ArticlesList";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Suspense from "components/Suspense";
import ErrorBoundary from "components/ErrorBoundary";
import { LuRefreshCw } from "react-icons/lu";

export default function RecommendedArticles() {
  const schema = yup.object({
    organisationId: yup
      .string()
      .url("Must be a valid URL")
      .required("This field is required"),
  });
  const resolver = yupResolver(schema);
  const form = useForm({ resolver });
  const organisationId = form.watch("organisationId");

  return (
    <Stack>
      <Heading>Recommendations</Heading>
      <FormProvider {...form}>
        <SelectOrganisation />

        {organisationId && (
          <ErrorBoundary>
            <Suspense>
              <RelevantArticles />
            </Suspense>
          </ErrorBoundary>
        )}
      </FormProvider>
    </Stack>
  );
}

function SelectOrganisation() {
  const form = useFormContext();

  const organisationId = form.watch("organisationId");

  const {
    data: { organisations },
  } = useSWR("organisations", () => fetcher("/organisations"), {
    suspense: true,
    revalidateOnMount: false,
  });

  return (
    <Select
      placeholder="Select organisation"
      value={organisationId}
      onChange={(e: any) => {
        form.setValue("organisationId", e.target.value);
      }}
    >
      {organisations.map((organisation: Organisation) => (
        <option key={organisation.id} value={organisation.id}>
          {organisation.name}
        </option>
      ))}
    </Select>
  );
}

function RelevantArticles() {
  const form = useFormContext();
  const organisationId = form.watch("organisationId");

  const { mutate } = useSWRConfig();
  const {
    data: { keywords, articles },
  } = useSWR(
    `${organisationId}-articles`,
    () => fetcher(`/organisations/${organisationId}/relevant-articles`),
    {
      suspense: true,
    }
  );

  const refresh = useCallback(
    () => mutate(`${organisationId}-articles`),
    [organisationId, mutate]
  );

  return (
    <Stack>
      <Flex gap={2} align="middle">
        <Text my="auto">Keywords:</Text>
        {keywords.map((keyword: string) => (
          <Tag key={keyword}>{keyword}</Tag>
        ))}
        <Flex>
          <Tooltip label="Refresh">
            <IconButton
              w="fit-content"
              aria-label="refresh-articles"
              icon={<LuRefreshCw />}
              onClick={refresh}
            />
          </Tooltip>
        </Flex>
      </Flex>

      {articles && <ArticlesList articles={articles} />}
    </Stack>
  );
}
