import { apiClient } from "utils/api";

export function fetchTopicRecommendations({ websiteId }: any) {
  const promise = apiClient
    .get(`/websites/${websiteId}/recommended-blog-topics`)
    .then((res) => {
      return res.data;
    });

  return promise;
}

export function generateBlog({ template }: any) {
  const promise = apiClient.post(`/generate-blog`, template).then((res) => {
    return res.data;
  });

  return promise;
}

export async function createImage({
  description,
  size = "512x512",
  responseFormat = "b64_json",
}: {
  description: string;
  size?: string;
  responseFormat?: string;
}) {
  const res = await apiClient.post(`/generate-image`, {
    description,
    size,
    responseFormat,
  });
  return res.data;
}

export async function publishPost({ post }: any) {
  const res = await apiClient.post("/posts", post);
  return res.data;
}

export async function rephraseContent({ content, comment }: any) {
  const res = await apiClient.post("/rephrase-content", {
    content,
    comment,
  });
  return res.data;
}

export async function generateSharePostForBlog({
  postId,
  platform,
}: {
  postId: string;
  platform: string;
}) {
  const res = await apiClient.post(
    `/generate-share-post?postId=${postId}&platform=${platform}`
  );
  return res.data;
}
