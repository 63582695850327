import { useCallback, useEffect } from "react";
import { Stack, Flex, Button, useToast } from "@chakra-ui/react";
import { useState } from "react";
import { Message } from "types";
import { emailReply } from "../api";
import { FaReply, FaReplyAll } from "react-icons/fa";
import { MdCancel } from "react-icons/md";
import { RiShareForwardFill } from "react-icons/ri";
import MailEditor from "./MailEditor";
import useSWR, { useSWRConfig } from "swr";
import { fetcher } from "utils/api";
import { BsStars } from "react-icons/bs";

type ReplyMailProps = {
  originalMessage: Message;
  autoGenerateReply?: boolean;
};
export default function ReplyMail({
  originalMessage,
  autoGenerateReply = true,
}: ReplyMailProps) {
  const { mutate } = useSWRConfig();

  const { data: profile } = useSWR("/users/me/profile", fetcher, {
    suspense: true,
  });

  const { from, cc, to } = originalMessage;

  const toast = useToast();
  const [message, setMessage] = useState<any>({
    from: profile.email,
    body: originalMessage.reply,
  });
  const [show, setShow] = useState<boolean>(false);

  const [generatingReply, setGeneratingReply] = useState<boolean>(false);

  // Message generation handlers
  const handleGenerateReply = useCallback(async () => {
    try {
      setGeneratingReply(true);
      toast({
        title: "Generating reply...",
        status: "info",
        duration: null, // Persistent until manually closed
        isClosable: true,
      });

      const { reply } = await emailReply(originalMessage);

      setMessage((prev: any) => ({ ...prev, body: reply }));

      setShow(true);

      toast.closeAll(); // Close the loading toast
      toast({
        title: "Reply generated successfully!",
        status: "success",
        duration: 3000,
      });
    } catch (error) {
      toast.closeAll(); // Close any existing toasts
      toast({
        title: "Error generating reply",
        status: "error",
        duration: 5000,
      });
    } finally {
      setGeneratingReply(false);
    }
  }, [originalMessage, toast]);

  const handleReply = useCallback(async () => {
    setMessage((prev: any) => ({
      ...prev,
      to: originalMessage.from,
      replyTo: originalMessage,
      subject: `Re: ${originalMessage.subject}`,
    }));
    setShow(true);

    if (!message.body && !generatingReply && autoGenerateReply) {
      handleGenerateReply();
    }
  }, [
    originalMessage,
    message.body,
    generatingReply,
    autoGenerateReply,
    handleGenerateReply,
  ]);

  const handleReplyAll = useCallback(() => {
    if (!message.body && !generatingReply) {
      handleGenerateReply();
    }
    setShow(true);
    setMessage((prev: any) => ({
      ...prev,
      to: [from, cc, to].filter(Boolean).join(", "),
      replyTo: originalMessage,
      subject: `${!originalMessage.subject.startsWith("Re:") ? "Re: " : ""}${
        originalMessage.subject
      }`,
    }));
  }, [
    from,
    cc,
    to,
    originalMessage,
    message.body,
    generatingReply,
    handleGenerateReply,
  ]);

  const handleForward = useCallback(() => {
    setShow(true);
    setMessage((prev) => ({
      ...originalMessage,
      from: prev?.from ?? "",
      to: "",
      body: `<br/>---------- Forwarded message ---------<br/>${originalMessage.body}`,
      subject: `${!originalMessage.subject.startsWith("Fwd:") ? "Fwd: " : ""}${
        originalMessage.subject
      }`,
    }));
  }, [originalMessage]);

  return (
    <Stack>
      <Flex gap={2}>
        {show && (
          <Button leftIcon={<MdCancel />} onClick={() => setShow(false)}>
            Cancel
          </Button>
        )}
        <Button onClick={handleReply} leftIcon={<FaReply />}>
          Reply
        </Button>
        <Button onClick={handleReplyAll} leftIcon={<FaReplyAll />}>
          Reply all
        </Button>
        <Button onClick={handleForward} leftIcon={<RiShareForwardFill />}>
          Forward
        </Button>
        <Button
          onClick={handleGenerateReply}
          leftIcon={<BsStars />}
          isLoading={generatingReply}
          colorScheme="blue"
        >
          Generate Reply
        </Button>
      </Flex>
      {show && (
        <MailEditor
          disabled={generatingReply}
          message={message}
          onSend={() => {
            mutate(`/mails/threads/${originalMessage.threadId}/messages`);
            setShow(false);
          }}
        />
      )}
    </Stack>
  );
}
