import { Button, Flex, useToast, Input } from "@chakra-ui/react";
import { useState } from "react";
import { BsStars } from "react-icons/bs";
import { createImage } from "views/admin/content-generator/api";

export type ImageResponse = {
    image?: string;
    url?: string;
    data?: string;
}

type ImageGeneratorProps = {
  buttonText?: string;
  defaultDescription: string;
  onGenerate: (res: ImageResponse) => void;
  disabled?: boolean;
  showInput: boolean;
};

export default function ImageGenerator({
  buttonText = "Generate image",
  defaultDescription = "",
  onGenerate,
  disabled = false,
  showInput = false,
}: ImageGeneratorProps) {
  const toast = useToast();
  const [description, setDescription] = useState<string>("");

  const generateImage = () => {
    const promise = createImage({
      description: description ? description : defaultDescription,
      size: "512x512",
      responseFormat: "url",
    })
      .then((res: any) => {
        onGenerate(res);
      })
      .catch((err) => {
        toast({
          title: "Something went wrong when generating the image.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      });

    toast.promise(promise, {
      loading: { title: "Generating image..." },
      success: { title: "Image generated" },
      error: { title: "Something went wrong when generating the image." },
    });
  };

  return (
    <Flex gap={2}>
      {showInput && (
        <Input
          value={description}
          onChange={(e: any) => setDescription(e.target.value)}
          placeholder="Enter description for image"
        />
      )}
      <Button
        width="fit-content"
        colorScheme="blue"
        onClick={generateImage}
        leftIcon={<BsStars />}
        disabled={disabled || !(description || defaultDescription)}
      >
        {buttonText}
      </Button>
    </Flex>
  );
}
